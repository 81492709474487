
import Vue from 'vue';
import { mapActions } from 'vuex';
export default Vue.extend({
  name: 'TimesheetDetailPage',

  data() {
    return {
      loading: true
    };
  },

  computed: {
    timesheetId(): number {
      return Number(this.$route.params.timesheetId);
    }
  },

  methods: {
    ...mapActions({
      fetchTimesheet: 'timesheets/fetchTimesheet'
    })
  },

  async mounted() {
    this.fetchTimesheet(this.timesheetId).finally(() => (this.loading = false));
  }
});
